<template>
  <div class="user-upsert">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Tên tài khoản:"
              label-class="font-weight-bold"
              class="required-control"
              label-for="#"
              :state="validState('userForm.username')"
              :invalid-feedback="getInvalidFeedback($v.userForm.username)"
            >
              <b-input
                size="sm"
                single-line
                class="input-style text-left"
                placeholder="Nhập tên tài khoản"
                v-model.trim="$v.userForm.username.$model"
                :class="{ 'is-invalid': $v.userForm.username.$error }"
                :disabled="isMode(['update', 'view'])"
                @input="onInputChange"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Mật khẩu:"
              label-class="font-weight-bold"
              class="required-control"
              label-for="#"
              :description="
                isMode('create')
                  ? 'Có thể xem mật khẩu khi tạo mới'
                  : isMode('update')
                  ? 'Tạo mật khẩu mới khi cập nhật'
                  : ''
              "
            >
              <b-input-group size="sm">
                <b-input
                  size="sm"
                  single-line
                  placeholder="Tạo mật khẩu tự động"
                  class="input-style text-left"
                  :type="isTypeChange ? 'text' : 'password'"
                  v-model="userForm.password"
                  :disabled="true"
                />
                <b-input-group-append v-if="isMode(['create', 'update'])">
                  <b-button variant="secondary" @click="onClickAppendPassword">
                    <span v-if="isMode('create')">
                      <i
                        class="fa-regular ml-1"
                        style="width: 20px"
                        :class="isTypeChange ? 'fa-eye-slash' : 'fa-eye'"
                      ></i>
                    </span>
                    <span v-if="isMode('update')">
                      <i
                        class="fa-solid fa-rotate ml-1"
                        style="width: 20px"
                      ></i>
                    </span>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Nhân sự:"
              label-class="font-weight-bold"
              class="required-control"
              label-for="#"
            >
              <Autosuggest
                :suggestions="optionsEmployee"
                :model="userForm.employeeName"
                :placeholder="'nhân sự'"
                @change="onChange($event, 'employee')"
                @select="onSelected($event, 'employee')"
                :state="validState('userForm.employeeId')"
                :limit="10"
                :disabled="isMode('view')"
                errorMessages="Yêu cầu chọn nhân sự."
                suggestionName="fullName"
              >
                <template #custom="{ suggestion }">
                  <div>
                    <span>{{ suggestion.item.fullName }}</span>
                    <span class="text-primary" v-if="suggestion.item.code">
                      - {{ suggestion.item.code }}
                    </span>
                  </div>
                </template>
              </Autosuggest>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Chọn quyền:"
              label-class="font-weight-bold"
              class="required-control"
              label-for="#"
            >
              <Autosuggest
                :suggestions="optionsRole"
                :model="userForm.roleName"
                :placeholder="'quyền'"
                :limit="10"
                :disabled="isMode('view')"
                @change="onChange($event, 'role')"
                @select="onSelected($event, 'role')"
                :state="validState('userForm.roleId')"
                errorMessages="Yêu cầu chọn quyền."
                suggestionName="name"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Trạng thái:"
              label-class="font-weight-bold"
              class="required-control"
              label-for="#"
            >
              <b-form-select
                v-model="userForm.isActive"
                class="select-style"
                size="sm"
                :options="optionsStatus"
                value-field="id"
                text-field="name"
                disabled-field="notEnabled"
                :state="validState('userForm.isActive')"
                :disabled="isMode('view')"
              >
              </b-form-select>
              <b-form-invalid-feedback>
                Vui lòng chọn trạng thái
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group>
              <div class="d-flex w-100">
                <label class="font-weight-bold d-inline"> Email:</label>
                <div
                  v-if="(userForm.email && isMode('create')) || isRenewPassword"
                  class="d-inline align-item-center ml-1 w-100"
                >
                  <small
                    @click="notifyToGmail(userForm.sentMail)"
                    class="cursor-pointer float-right mt-1"
                  >
                    <span v-if="userForm.sentMail" class="text-primary">
                      <span>
                        <i
                          class="fa-solid fa-check text-primary fa-sm align-middle mr-1"
                        ></i>
                      </span>
                      <span>Gửi mail tự động </span>
                    </span>
                    <span v-else class="text-danger">
                      <span>
                        <i
                          class="fa-solid fa-xmark text-danger fa-sm align-middle mr-1"
                        ></i>
                      </span>
                      <span>Tắt gửi mail tự động </span>
                    </span>
                  </small>
                </div>
              </div>
              <b-input
                size="sm"
                single-line
                class="input-style text-left"
                :value="userForm.email"
                placeholder="Email chưa được đăng kí"
                :disabled="true"
              />
              <small v-if="userForm.email === ''" class="text-warning">
                Tài khoản chưa đăng ký gmail không thể gửi mail
              </small>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Hành động:"
              label-class="font-weight-bold"
              label-for="#"
            >
              <b-form-select
                v-model="userForm.actionMode"
                class="select-style"
                size="sm"
                :options="optionsActionMode"
                value-field="id"
                text-field="name"
                disabled-field="notEnabled"
                :disabled="isMode(['view', 'create'])"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0"
                    >Không có hành động</b-form-select-option
                  >
                </template>
                <template v-if="isMode('update')">
                  <b-form-select-option :value="1" disabled>
                    Yêu cầu đổi mật khẩu lần đầu</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template v-slot:foot>
        <div>
          <b-button
            v-if="isMode(['update', 'create'])"
            class="font-weight-bolder button-width"
            variant="primary"
            size="sm"
            @click="upsertUser"
            >{{ mode[currentMode].titleBtn }}</b-button
          >
          <b-button
            class="button-width font-weight-bolder ml-2"
            variant="secondary"
            size="sm"
            @click="$router.go(-1)"
            >Hủy</b-button
          >
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>


  <script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import localData from '@/utils/saveDataToLocal';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { minLength, maxLength, required } from 'vuelidate/lib/validators';
import {
  makeToastFaile,
  makeToastSuccess,
  xoa_dau,
  cloneDeep,
  removeAccents,
} from '@/utils/common';
import debounce from 'debounce';

export default {
  mixins: [validationMixin],
  data() {
    return {
      title: 'Tạo mới tài khoản',
      mode: {
        update: {
          title: 'Cập nhật tài khoản',
          titleBtn: 'Cập nhật',
        },
        create: {
          title: 'Tạo mới tài khoản',
          titleBtn: 'Tạo mới',
        },
        view: {
          title: 'Thông tin tài khoản',
        },
      },
      currentMode: '',
      userId: null,
      optionsEmployee: [],
      optionsStatus: [],
      optionsRole: [],
      optionsActionMode: [],
      searchEmployee: null,
      optionOrigin: {},
      userForm: {
        id: null,
        username: null,
        employeeId: null,
        employeeName: null,
        password: null,
        roleId: null,
        roleName: null,
        isActive: 1,
        sentMail: false,
        email: null,
        actionMode: 0,
      },
      isExistUsername: true,
      isTypeChange: false,
      isRenewPassword: false,
      isBusy: false,
    };
  },
  validations: {
    userForm: {
      username: {
        required,
        regex(value) {
          const containsSpecial = /^[a-zA-Z0-9_.]+$/.test(value);
          return containsSpecial;
        },
        minLength: minLength(3),
        maxLength: maxLength(100),
        isExist() {
          return this.isExistUsername;
        },
      },
      employeeId: {
        required,
      },
      roleId: {
        required,
      },
      isActive: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    Autosuggest,
  },
  created() {
    this.userId = this.$route.query.id || null;
    this.currentMode = this.getMode();

    this.title = this.mode[this.currentMode].title;
    switch (this.currentMode) {
      case 'update': {
        this.getUserById(this.userId);
        this.fetchRoles();
        this.fetchEmployees();
        this.optionsActionMode.push(
          ...[
            { id: 2, name: 'Yêu cầu đăng nhập lại' },
            { id: 3, name: 'Yêu cầu đăng nhập lại và đổi mật khẩu' },
          ],
        );
        break;
      }
      case 'create': {
        this.generatePassword();
        this.fetchRoles();
        this.fetchEmployees();
        this.userForm.actionMode = 1;
        this.optionsActionMode.push({
          id: 1,
          name: 'Yêu cầu đổi mật khẩu lần đầu',
        });
        break;
      }
    }
    this.optionsStatus.push(
      ...[
        { id: 1, name: 'Hoạt động' },
        { id: 2, name: 'Đang Khóa' },
      ],
    );

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Tài khoản', route: '/users' },
      { title: 'Danh sách tài khoản', route: '/users' },
      { title: this.mode[this.currentMode].title },
    ]);
  },
  mounted() {},
  methods: {
    getMode() {
      return this.userId && this.checkPermission('USER_UPDATE')
        ? 'update'
        : this.checkPermission('USER_INSERT')
        ? 'create'
        : 'view';
    },
    isMode(param) {
      if (Array.isArray(param)) {
        return param.includes(this.currentMode);
      }
      return this.currentMode === param;
    },
    validState(fieldname) {
      if (typeof fieldname !== 'string') {
        throw new Error('fieldname shoule be string');
      }
      const splitData = fieldname.split('.');
      const [model] = splitData;
      if (!model) {
        throw new Error('Model can not be empty');
      }
      if (!this.$v[model]) {
        throw new Error(`${model} can not declare in validations`);
      }
      if (this.$v[model] && this.$v[model][splitData[1]]) {
        const { $dirty, $error } = this.$v[model][splitData[1]];
        return $dirty ? !$error : null;
      }
    },
    getInvalidFeedback(validation) {
      if (!validation.$dirty) return '';
      if (!validation.minLength) return 'Tên tài khoản tối thiểu 3 ký tự';
      if (!validation.required) return 'Vui lòng nhập tên đăng nhập';
      if (!validation.isExist) return 'Tài khoản đã tồn tại trong hệ thống';
      if (!validation.regex) return 'Tên tài khoản chỉ được chứa kí tự (_.)';
      if (!validation.maxLength) return 'Tên tài khoản quá dài';

      return '';
    },
    catchAnyError(model) {
      this.$v[model].$touch();
      return this.$v[model].$anyError;
    },
    upsertUser() {
      const hasErrors = this.catchAnyError('userForm');
      if (hasErrors) return;
      if (this.isBusy) return;
      this.isBusy = true;
      const method = this.userId ? 'put' : 'post';
      ApiService[method]('user', {
        ...this.userForm,
      })
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.$router.push({
              path: '/users',
            });
          } else {
            makeToastFaile(data.message);
          }
          this.isBusy = false;
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
          this.isBusy = false;
        });
    },
    fetchRoles: async function () {
      this.optionsRole = [];
      ApiService.query('role', {
        params: {
          page: 1,
          limit: 1000,
        },
      }).then((response) => {
        const { data } = response.data;
        const roles = data.list_role.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });

        this.optionOrigin.optionsRole = cloneDeep(roles);
        this.optionsRole = roles;
      });
    },
    getUserById: function (userId) {
      if (!userId) {
        throw new Error('Yêu cầu userId');
      }
      ApiService.get(`user/${userId}`)
        .then((response) => {
          const { data, status } = response.data;
          if (status !== 1) {
            makeToastFaile('Tải dữ liệu thất bại!');
            return;
          }
          this.userForm = Object.assign(this.userForm, data);
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    fetchEmployees: async function () {
      ApiService.query('employees/getAll', {
        params: {
          orderBy: ['createdAt', 'DESC'],
        },
      }).then((response) => {
        const listEmployee = response.data.data;

        const optionsEmployee = listEmployee.map((item) => {
          const nameNoneSign = xoa_dau(item.fullName);
          return {
            id: item.id,
            fullName: nameNoneSign,
            nameNoneSign: nameNoneSign,
            code: item.code,
            email: item.email,
          };
        });
        this.optionsEmployee = optionsEmployee;
        this.optionOrigin.optionsEmployee = cloneDeep(optionsEmployee);
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onSelected(option, type) {
      switch (type) {
        case 'employee': {
          this.userForm.employeeId = option.item.id;
          this.userForm.employeeName = option.item.fullName;
          const email = option.item.email || '';
          this.userForm.email = email;
          this.userForm.sentMail = email ? true : false;
          break;
        }
        case 'role': {
          this.userForm.roleId = option.item.id;
          this.userForm.roleName = option.item.name;
          break;
        }
      }
    },
    onChange(textInput = '', type) {
      switch (type) {
        case 'employee': {
          this.userForm.employeeId = null;
          this.userForm.email = null;
          this.optionsEmployee = this.fitlerOptionsBy(
            this.optionOrigin.optionsEmployee,
            textInput,
            'fullName',
            10,
          );
          break;
        }
        case 'role': {
          this.userForm.roleId = null;
          this.optionsRole = this.fitlerOptionsBy(
            this.optionOrigin.optionsRole,
            textInput,
            'name',
            10,
          );
          break;
        }
      }
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    onInputChange(username) {
      this.isExistUsername = true;
      const usernameCheck = username ? username.trim() : '';
      if (!usernameCheck || usernameCheck.length < 4) return;
      this.debounceInput(username);
    },
    debounceInput: debounce(function (textSearch) {
      this.checkUsername(textSearch);
    }, 1000),
    checkUsername(username) {
      return ApiService.query(`user/check`, {
        params: {
          type: 'user-name-exist',
          query: {
            username: username,
          },
        },
      })
        .then((response) => {
          const { data, status } = response;
          if (![200, 304].includes(status)) {
            makeToastFaile('Có lỗi xảy ra vui lòng thử lại');
            throw new Error('Có lỗi xảy ra vui lòng thử lại');
          }
          this.isExistUsername = data.data ? false : true;
        })

        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    generatePassword() {
      return ApiService.get(`user/password/generate`)
        .then((response) => {
          const { data, status } = response;
          if (![200, 304].includes(status)) {
            makeToastFaile('Có lỗi xảy ra vui lòng thử lại');
            throw new Error('Có lỗi xảy ra vui lòng thử lại');
          }
          this.userForm.password = data.data;
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    notifyToGmail(option) {
      this.userForm.sentMail = !option;
      makeToastSuccess(
        this.userForm.sentMail ? 'Thông báo về gmail' : 'Bỏ thông báo về gmail',
      );
    },
    onClickAppendPassword() {
      if (this.isMode('create')) {
        this.isTypeChange = !this.isTypeChange;
        return;
      }
      if (this.isMode('update')) {
        this.generatePassword();
        this.isRenewPassword = true;
      }
    },
  },
};
</script>
  <style scoped>
.button-width {
  width: 100px;
}
</style>